<template>
    <v-row>
        <v-dialog v-model="dialog" scrollable>
            <v-card outlined max-width="575">
                <v-card-title class="text-h5">{{tituloDialog}} - {{dadosDialog}}</v-card-title>
                <v-card-text>
                    <v-divider></v-divider>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>...</th>
                                <th>...</th>
                                <th>...</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>...</td>
                                <td>...</td>
                                <td>...</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-col cols="12" md="12">
            <v-card>
                <v-card-text>
                    <v-form>
                        <v-row class="mb-n12">
                            <v-col class="pl-2 pr-1 pl-lg-3 d-none d-md-block">
                                <InputDatePicker
                                    :disabled="carregando"
                                    v-model="busca.dtinicio"
                                    label="Data inicial"
                                    :outlined="true"
                                    :dense="true"
                                />
                            </v-col>
                            <v-col class="px-1 d-none d-md-block">
                                <InputDatePicker
                                    :disabled="carregando"
                                    v-model="busca.dtfim"
                                    label="Data final"
                                    :outlined="true"
                                    :dense="true"
                                />
                            </v-col>
                            <v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
                                <v-sheet class="text-center" height="100%">
                                    <v-row class="pa-3">
                                        <v-col cols="6">
                                            <InputDatePicker
                                                :disabled="carregando"
                                                v-model="busca.dtinicio"
                                                label="Data inicial"
                                                :outlined="true"
                                                :dense="true"
                                            />
                                        </v-col>
                                        <v-col cols="6">
                                            <InputDatePicker
                                                :disabled="carregando"
                                                v-model="busca.dtfim"
                                                label="Data final"
                                                :outlined="true"
                                                :dense="true"
                                            />
                                        </v-col>
                                        <v-col cols="6">
                                            <v-btn
                                                text
                                                class="mt-n3"
                                                color="primary"
                                                @click="sheet = !sheet"
                                            >Cancelar</v-btn>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-btn
                                                text
                                                class="mt-n3"
                                                color="primary"
                                                @click="filtrar(0), sheet = !sheet"
                                            >Filtrar</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-sheet>
                            </v-bottom-sheet>
                            <v-col class="px-1 d-flex">
                                <v-select
                                    class="d-inline-flex d-md-none"
                                    clearable
                                    :items="['João', 'Maria', 'José', 'Mario']"
                                    label="Vendedor"
                                    :disabled="carregando"
                                    outlined
                                    dense
                                    append-outer-icon="mdi-menu"
                                    @click:append-outer="sheet = !sheet"
                                ></v-select>
                                <v-select
                                    class="d-none d-md-block"
                                    clearable
                                    :items="['João', 'Maria', 'José', 'Mario']"
                                    label="Vendedor"
                                    :disabled="carregando"
                                    outlined
                                    dense
                                ></v-select>
                                <v-btn
                                    :disabled="carregando"
                                    class="mx-2 mt-1"
                                    color="primary"
                                    elevation="0"
                                    fab
                                    x-small
                                >
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <span class="text-caption text--disabled pl-3">* Atualizado em: 10:52:34 14/12/2021</span>
            </v-card>
        </v-col>
        <v-col cols="12" md="12">
            <v-row>
                <v-col>
                    <CardDashboard :loading="carregando" title="Vendas realizadas">
                        <template>R$ 257.535,00</template>
                    </CardDashboard>
                </v-col>
                <v-col>
                    <CardDashboard :loading="carregando" title="Margem de vendas">
                        <template>17,72</template>
                    </CardDashboard>
                </v-col>
                <v-col>
                    <CardDashboard :loading="carregando" title="Ticket médio">
                        <template>R$ 22.519,19</template>
                    </CardDashboard>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12">
            <v-card>
                <v-card-title
                    class="d-inline-block col-12 text-truncate"
                >Vendas realizadas x Meta diária por filial</v-card-title>
                <v-card-text>
                    <GraficoBarDashboard
                        :dataSeries="[
                                {name: 'Valor vendido', data: ['115986.37', '158697.42', '85987.59', '60325.12', '117589.71', '118589.37', '123589.42', '75589.59', '98589.12', '117589.71', '118589.37', '123589.42', '75589.59', '98589.12', '117589.71', '118589.37', '123589.42', '75589.59', '98589.12', '117589.71', '118589.37', '123589.42', '75589.59', '98589.12', '117589.71', '118589.37'], id: ['2', '5', '7', '510', '635']},
                                {name: 'Meta diária', data: ['118589.37', '123589.42', '75589.59', '98589.12', '117589.71', '118589.37', '123589.42', '75589.59', '98589.12', '117589.71', '118589.37', '123589.42', '75589.59', '98589.12', '117589.71', '118589.37', '123589.42', '75589.59', '98589.12', '117589.71', '118589.37', '123589.42', '75589.59', '98589.12', '117589.71', '118589.37'], id: ['2', '5', '7', '510', '635']},
                        ]"
                        :categories="['Matriz', 'Pitanga', 'São João do Ivaí', 'Castelo', 'Cândido de Abreu', 'Apucarana', 'Jardim Alegre', 'Manoel Ribas', 'Faxinal', 'Campo Mourão', 'Cianorte', 'Paranavaí', 'Telemaco Borba', 'Roncador', 'Guarapuava', 'Palmital', 'Ibiporã', 'São Mateus do Sul', 'São João do Triunfo', 'Pontal do Paraná', 'Mandaguari', 'Astorga', 'Cambé', 'Reserva', 'Rolândia', 'Maringá']"
                        titleY="R$"
                        :horizontal="false"
                        :money="true"
                        height="450"
                        :distributed="false"
                    />
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="6">
            <v-card>
                <v-card-title
                    class="d-inline-block col-12 text-truncate"
                >Vendas por prazo de pagamento</v-card-title>
                <v-card-text>
                    <GraficoPieDashboard
                        :dataSeries="[1235, 825]"
                        :labels="['à vista', 'à prazo']"
                    />
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="6">
            <v-card>
                <v-card-title
                    class="d-inline-block col-12 text-truncate"
                >Vendas por forma de pagamento</v-card-title>
                <v-card-text>
                    <GraficoPieDashboard
                        :dataSeries="[134, 238, 358, 58, 122]"
                        :labels="['Dinheiro', 'Cartão Débito', 'Cartão Crédito', 'Cheque', 'Depósito']"
                    />
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card>
                <v-card-title
                    class="d-inline-block col-12 text-truncate"
                >Ticket médio x Clientes atendidos por filial</v-card-title>
                <v-card-text>
                    <GraficoLineColumnDashboard
                        :dataSeries="[
                            {name: 'Ticket médio', type: 'line', data: ['489.37', '382.42', '757.59', '257.12', '425.71', '489.37', '382.42', '757.59', '257.12', '425.71', '489.37', '382.42', '757.59', '257.12', '425.71', '489.37', '382.42', '757.59', '257.12', '425.71', '489.37', '382.42', '757.59', '257.12', '425.71', '489.37'], id: ['2', '5', '7', '510', '635']},
                            {name: 'Clientes atendidos', type: 'column', data: ['542', '375', '677', '627', '425', '542', '375', '677', '627', '425', '542', '375', '677', '627', '425', '542', '375', '677', '627', '425', '542', '375', '677', '627', '425', '542'], id: ['2', '5', '7', '510', '635']},
                            {name: 'Margem', type: 'line', data: ['27.72', '29.35', '25.81', '19.78', '26.17', '27.72', '29.35', '25.81', '19.78', '26.17', '27.72', '29.35', '25.81', '19.78', '26.17', '27.72', '29.35', '25.81', '19.78', '26.17', '27.72', '29.35', '25.81', '19.78', '26.17', '27.72'], id: ['2', '5', '7', '510', '635']},
                        ]"
                        :labels="['Matriz', 'Pitanga', 'São João do Ivaí', 'Castelo', 'Cândido de Abreu', 'Apucarana', 'Jardim Alegre', 'Manoel Ribas', 'Faxinal', 'Campo Mourão', 'Cianorte', 'Paranavaí', 'Telemaco Borba', 'Roncador', 'Guarapuava', 'Palmital', 'Ibiporã', 'São Mateus do Sul', 'São João do Triunfo', 'Pontal do Paraná', 'Mandaguari', 'Astorga', 'Cambé', 'Reserva', 'Rolândia', 'Maringá']"
                        :titlesY="[
                            { title: { text: 'Ticket médio' } }, 
                            { title: { text: 'Clientes atendidos' } },
                            { opposite: true, title: { text: 'Margem' } }, 
                        ]"
                        :tooltipsY="[
                            { enabled: true, formatter: function (val) { return parseFloat(val).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) } },
                            { enabled: true, formatter: function (val) { return val.toFixed(0) } },
                            { enabled: true, formatter: function (val) { return val + '%' } },
                        ]"
                        height="450"
                    />
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import InputDatePicker from "../Widgets/InputDatePicker";
import CardDashboard from "../Widgets/CardDashboard";
import GraficoBarDashboard from "../Widgets/GraficoBarDashboard";
import GraficoPieDashboard from "../Widgets/GraficoPieDashboard";
import GraficoLineColumnDashboard from "../Widgets/GraficoLineColumnDashboard";

export default {
    components: {
        InputDatePicker,
        CardDashboard,
        GraficoBarDashboard,
        GraficoPieDashboard,
        GraficoLineColumnDashboard,
    },
    name: "UsuarioDashboardSolar",
    data: () => ({
        sheet: false,
        dialog: false,
        tituloDialog: "",
        dadosDialog: {},
        carregando: false,
        filiais: {},
        busca: {
            dtinicio: `${new Date().getFullYear()}-${
                (new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                ).getMonth() +
                    1 <
                10
                    ? "0"
                    : "") +
                (new Date().getMonth() + 1)
            }-01`,
            dtfim: `${new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 1,
                0
            ).getFullYear()}-${
                (new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                ).getMonth() +
                    1 <
                10
                    ? "0"
                    : "") +
                (new Date().getMonth() + 1)
            }-${new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 1,
                0
            ).getDate()}`,
            idfilial: 0,
        },

        aguardarBusca: "",
        nomeLimit: 50,
        vendedores: [],
        search: null,
        searchMobile: null,
    }),
    computed: {
        ...mapState(["backendUrl", "pgLimit", "timeLimit", "usuario"]),
        items() {
            if (this.vendedores == null) return;

            this.vendedores.forEach((vendedor) => {
                if (vendedor.nome.length > this.nomeLimit) {
                    vendedor.nome =
                        vendedor.nome.slice(0, this.nomeLimit) + "...";
                }
            });

            return this.vendedores;
        },
    },
    methods: {
        dialogConsulta(titulo, dados) {
            this.dialog = true;
            this.tituloDialog = titulo;
            this.dadosDialog = dados;
        },
        filtrar() {},
        carregarVendedores(val) {
            clearTimeout(this.aguardarBusca);

            this.aguardarBusca = setTimeout(() => {
                this.carregando = true;
                return axios
                    .post(`${this.backendUrl}usuario/listar`, {
                        limit: 30,
                        offset: 0,
                        busca: val || null,
                        idgrupo: 1, //colocar id grupo vendedor
                    })
                    .then((res) => {
                        this.vendedores = res.data.lista;
                        this.carregando = false;
                    })
                    .catch(() => {
                        this.carregando = false;
                    });
            }, this.timeLimit);
        },
    },
    watch: {
        "busca.dtfim": function () {
            if (this.busca.dtfim < this.busca.dtinicio) {
                this.busca.dtinicio = this.busca.dtfim;
            }
        },
        "busca.dtinicio": function () {
            if (this.busca.dtfim < this.busca.dtinicio) {
                this.busca.dtfim = this.busca.dtinicio;
            }
        },
        search: function (val) {
            this.carregarVendedores(val);
        },
        searchMobile: function (val) {
            this.carregarVendedores(val);
        },
    },
    created() {
        this.busca.idfilial = this.usuario.idempresa;
    },
};
</script>

<style scoped>
</style>